import 'consumerweb-shim';
import 'core-js/stable'; // If imports from pp-react are required, please test this component on IE
import 'regenerator-runtime/runtime';
import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../../components/footer/footer';
import templateData from '../../utility/getTemplateData';
import { hideSpinnerOverlay } from '../../utility/helper';
import withTheme from '../../components/withTheme';
import FullPageError from './FullPageError';
import '../../../css/ppvx-app.less';
import { trackErrorPageIM } from '../../fpti/fpti';
import { PAGE_SPEC } from '../../fpti/fptiConstants';
import withWorldReady from '../../components/withWorldReady';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
let webscrData = templateData.webscrData || {};
function WebscrError() {
  const worldReady = useContext(WorldReadyContext);
  useEffect(() => {
    const webscrErrorCode = templateData.webscrErrorCode;
    hideSpinnerOverlay();
    trackErrorPageIM({
      pageSpec: PAGE_SPEC.WEBSCR_ERROR_PAGE,
      errorCode: webscrErrorCode,
    });
  }, []);

  const webscrErrorCode = templateData.webscrErrorCode;

  let errorId = `errors.webscr.webscr.error.${webscrErrorCode}`;
  if (
    webscrErrorCode === 'NOT_ALLOWED_RECEIVABLE_CURRENCY' &&
    webscrData?.jsonParams?.currency_code?.length > 3
  ) {
    errorId = 'errors.webscr.webscr.error.BAD_INPUT_ERROR';
  }

  let webscrErrorMessage = new MessageFormat(worldReady, {
    id: errorId,
  }).format({ currency_code: webscrData?.jsonParams?.currency_code ?? '' });
  if (webscrErrorMessage == `errors.webscr.webscr.error.${webscrErrorCode}`) {
    webscrErrorMessage = new MessageFormat(worldReady, {
      id: 'errors.webscr.webscr.error.GENERIC_ERROR',
    }).format();
  }
  return (
    <>
      <FullPageError title={webscrErrorMessage} />
      <Footer />
    </>
  );
}

const WebscrErrorWithTheme = withTheme(WebscrError);
const WebscrErrorWithWorldReady = withWorldReady(WebscrErrorWithTheme);

ReactDOM.render(
  <WebscrErrorWithWorldReady />,
  document.getElementById('donateWrapper')
);
